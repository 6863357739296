<template>
  <div>
    <!-- 面包屑导航 -->
    <!--    <el-breadcrumb separator-class="el-icon-arrow-right" v-if="this.$router.currentRoute.path!='/home'">-->
    <!--      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>-->
    <!--      <el-breadcrumb-item>数据质量</el-breadcrumb-item>-->
    <!--      &lt;!&ndash;      <el-breadcrumb-item>项目</el-breadcrumb-item>&ndash;&gt;-->
    <!--      <el-breadcrumb-item>-->
    <!--        <span @click="back(-3)" style="font-weight: bold" v-if="this.$route.query.crumb">-->
    <!--          {{this.$route.query.crumb[0]}}-->
    <!--        </span>-->
    <!--        <span @click="back(-3)" style="font-weight: bold">系统</span>-->
    <!--      </el-breadcrumb-item>-->
    <!--      <el-breadcrumb-item>-->
    <!--        <span @click="back(-2)" style="font-weight: bold" v-if="this.$route.query.crumb">-->
    <!--          {{this.$route.query.crumb[1]}}-->
    <!--        </span>-->
    <!--        <span @click="back(-2)" style="font-weight: bold">子系统</span>-->
    <!--      </el-breadcrumb-item>-->
    <!--      <el-breadcrumb-item>-->
    <!--        <span @click="back(-1)" style="font-weight: bold" v-if="this.$route.query.crumb">-->
    <!--          {{this.$route.query.crumb[2]}}-->
    <!--        </span>-->
    <!--        <span @click="back(-1)" style="font-weight: bold">设备</span>-->
    <!--      </el-breadcrumb-item>-->
    <!--      <el-breadcrumb-item>-->
    <!--        <span v-if="this.$route.query.crumb">-->
    <!--          {{this.$route.query.crumb[3]}}-->
    <!--        </span>-->
    <!--        <span>附属设备</span>-->
    <!--      </el-breadcrumb-item>-->
    <!--    </el-breadcrumb>-->

    <el-breadcrumb separator-class="el-icon-arrow-right" v-if="this.$router.currentRoute.path!='/home'">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>数据清洗标准</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/dataQuality-dataQualitySystem' }">
        {{crumbA}}
      </el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/dataQuality-dataQualitySubsystem' }">
        {{crumbB}}
      </el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/dataQuality-dataQualityDevice' }">
        {{crumbC}}
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        {{crumbD}}
      </el-breadcrumb-item>
    </el-breadcrumb>

    <over-view-of-equipment  :table-data="tableData" :total="total" :totalMPNum="totalMPNum" v-if="overViewFlag"></over-view-of-equipment>
    <br>
    <!-- 页面主体区域 -->
	<div class="block" style="position: relative;" @click="showPanel('startPointId')">
	  <span class="demonstration" style="font-size: 10px;">搜索：</span>
	  <el-input style="display: inline-block;width: 500px;z-index: 111;" type="text" v-model="selectedOptions" suffix-icon="el-icon-arrow-down" placeholder="请选择设备"></el-input>
		<el-cascader
		id="startPointId"
			:options="options"
			separator=" | "
			style="width: 400px;"
			:props="props"
			placeholder="请选择设备"
			clearable
			ref="cascade"
			@change="handleSearch()"
		></el-cascader>
	</div>  
    <br/>
    <el-table
        :data="tableData"
        style="min-width: 100%"
        border
        v-loading="dataListLoading">
      <el-table-column
          label="序号"
          type="index"
          min-width="5%"
          align="center">
        <template slot-scope="scope">
          <span>{{(page - 1) * size + scope.$index + 1}}</span>
        </template>
      </el-table-column>
      <el-table-column
          prop="id"
          label="附属设备id"
          header-align="center"
          align="center"
          min-width="10%"
          v-if="false"
      >
      </el-table-column>
      <el-table-column
          prop="name"
          label="附属设备名称"
          header-align="center"
          align="center"
          min-width="10%">
      </el-table-column>
      <el-table-column
          prop="measurePointCount"
          label="数据项个数"
          header-align="center"
          align="center"
          min-width="10%"
      >
      </el-table-column>
      <el-table-column
          prop="createdate"
          label="创建时间"
          header-align="center"
          align="center"
          min-width="10%">
      </el-table-column>
      <el-table-column
          label="操作"
          header-align="center"
          align="center"
          min-width="10%">
        <template slot-scope="scope">
          <!--          <el-button-->
          <!--              type="text"-->
          <!--              size="small"-->
          <!--              @click="handleCheck(scope.row)"-->
          <!--              :disabled="scope.row.measurePointCount == 0?true:false"-->
          <!--          >查看</el-button>  &lt;!&ndash; 这里要改，当子系统个数为0时，查看按钮不可用 &ndash;&gt;-->
          <el-button
              type="text"
              size="small"
              @click="handleCheck(scope.row)"
          >查看</el-button>
        </template>
      </el-table-column>
      <el-table-column
          prop="remark"
          label="备注"
          header-align="center"
          align="center"
          min-width="20%">
      </el-table-column>
    </el-table>

    <div style="display: flex;justify-content: flex-end">
      <el-pagination
          background
          @current-change="currentChange"
          @size-change="sizeChange"
          :current-page="page"
          :page-size="size"
          layout="sizes, prev, pager, next, jumper, ->, total, slot"
          :total="total">
      </el-pagination>
    </div>
  </div>

</template>
<script>
import OverViewOfEquipment from "../coalSystemManage/overViewOfEquipment";
export default {
  name: "dataQualitySubdevice",
  components: {OverViewOfEquipment},
  data() {
    let that = this
    return {
      value: [],
      options: [],
		selectedOptions:"",
      tableData:[],
      // tableData: [{
      //     subdevice: '电机本体',
      //     createDate: '12h',
      //     measurePointNum:7,
      //     remark: '无'
      // }],
      page:1,  //当前第几页
      size:10, //当前每页个数
      total:0, //全部数据行数
      totalMPNum: 0, //全部数据项总数

      dataListLoading: false,

      currOperator: '',
      currOperatorName: '',

      checkVisible: false,
      currentdeviceId: 1,

      overViewFlag: false,

      props: {
        //级联选择器懒加载
        lazy: true,
        lazyLoad(node, resolve) {
          const { level } = node;
          console.log(level, node);
          if (level == 0) {
            that.getAllCoal((list1) => {
              let arr = list1.map((e) => ({ value: e.id, label: e.coal }));
              resolve(arr); // 通过调用resolve将子节点数据返回，通知组件数据加载完成
            });
          }
          if (level == 1) {
            let { value } = node;
            console.log(value);
            that.getSystem(value, (list2) => {
              console.log(list2);
              if (list2.length === 0) {
                let arr = [{
                  value: "无数据",
                  label: "无数据",
                  disabled: true,
                }]
                resolve(arr);
              } else {
                let arr = list2.map((e) => ({ value: e.id, label: e.name }));
                resolve(arr);
              }
            });
          }
          if (level == 2) {
            let { value } = node;
            console.log(value);
            that.getSubSystem(value, (list3) => {
              console.log(list3);
              if (list3.length === 0) {
                let arr = [{
                  value: "无数据",
                  label: "无数据",
                  disabled: true,
                }]
                resolve(arr);
              } else {
                let arr = list3.map((e) => ({ value: e.id, label: e.name }));
                resolve(arr);
              }
            });
          }
          if (level == 3) {
            let { value } = node;
            console.log(value);
            that.getEquipment(value, (list4) => {
              console.log(list4);
              if (list4.length === 0) {
                let arr = [{
                  value: "无数据",
                  label: "无数据",
                  disabled: true,
                  leaf: true,
                }]
                resolve(arr);
              } else {
                let arr = list4.map((e) => ({
                  value: e.id,
                  label: e.name,
                  leaf: true,
                }));
                resolve(arr);
              }
            });
          }
        },
      },
      crumbA: '',
      crumbB: '',
      crumbC: '',
      crumbD: '',
      sizeFromSet: 10,
    }
  },
  mounted(){
    //获取session中的user的id和name
    this.currOperator = JSON.parse(
        window.sessionStorage.getItem("user")
    ).userId;
    this.currOperatorName = JSON.parse(
        window.sessionStorage.getItem("user")
    ).username;

    if(this.global.equipment_id === 0) {
      this.initData_()
    } else {
      this.initDataWithGlobalVar()
    }

    // if (this.$route.query.hasOwnProperty("dataQualityDeviceId")) {
    //   this.handleJump();
    // } else {
    //   this.initData();
    // }
  },
  methods: {
    showPanel(val){
       document.getElementById(val).click()
    },
	// 级联表的方法
    handleChange() {
      this.dataListLoading = true
      if (this.$refs["cascade"].checkedValue === null ||this.$refs["cascade"].checkedValue.length == 0) {
        this.getRequest(
            "/subEquipmentManage/getSubEquipmentInfoByPage/?page=" +
            this.page +
            "&size=" +
            this.size + "&userId=" + this.currOperator
        ).then((resp) => {
          if (resp.data) {
            this.tableData = resp.data.data;
            this.total = resp.data.total;
            this.totalMPNum = resp.data.totalMPNum;
            this.overViewFlag = true;
          }
          this.dataListLoading = false
        });
      } else {
        if (this.$route.query.hasOwnProperty("crumb")) {
          for (let i = 0; i < this.$refs["cascade"].getCheckedNodes()[0].pathNodes.length; i++) {
            this.$route.query.crumb[i] = this.$refs["cascade"].getCheckedNodes()[0].pathNodes[i].label
          }
        }
        this.getRequest(
            "/subEquipmentManage/getSubEquipmentInfoByEquipmentId?equipmentId=" +
            this.$refs["cascade"].getCheckedNodes()[0].value + "&page=" + this.page + "&size=" + this.size
        ).then((resp) => {
          if (resp.data) {
            this.tableData = resp.data.data;
            this.total = resp.data.total;
            this.overViewFlag = true;
          }
          this.dataListLoading = false
        });
      }
    },
    // 监听 pagesize 改变的事件
    currentChange(currentPage){
      this.page=currentPage;
      this.initDataWithGlobalVar()
      // if(this.$route.query.hasOwnProperty("dataQualityDeviceId")) {
      //   if (this.$refs["cascade"].checkedValue === null) {
      //     this.handleJump()
      //   } else {
      //     this.handleChange()
      //   }
      // } else {
      //   this.handleChange();
      // }
    },
    sizeChange(currentSize) {
      this.size = currentSize;
      this.initDataWithGlobalVar()
      // if(this.$route.query.hasOwnProperty("dataQualityDeviceId")) {
      //   if (this.$refs["cascade"].checkedValue === null) {
      //     this.handleJump()
      //   } else {
      //     this.handleChange()
      //   }
      // } else {
      //   this.handleChange();
      // }
    },
    resetSizePage() {
      this.size = this.sizeFromSet
      this.page = 1
    },
    handleCheck(row) {
      this.global.sub_equipment_id = row.id
      this.global.sub_equipment_name = row.name
      this.$router.push({
        path: '/dataQualityMeasurePoint',
      })
      // if(this.$route.query.hasOwnProperty("crumb")) {
      //   this.$router.push({
      //     path: '/dataQualityMeasurePoint',
      //     query: {
      //       dataQualitySubDeviceId: row.id,
      //       crumb: [this.$route.query.crumb[0], this.$route.query.crumb[1], this.$route.query.crumb[2], this.$route.query.crumb[3], row.name]
      //     }
      //   });
      // } else {
      //   this.$router.push({
      //     path: '/dataQualityMeasurePoint',
      //     query: {
      //       dataQualitySubDeviceId: row.id,}
      //   });
      // }
    },
    initData_() {
      if (this.global.coal_id === 0) {
        this.getRequest("/dataList/getAllCoal/").then((resp) => {
          if (resp.data) {
            // 处理全局变量
            this.$changeGlobalVar(this.global.MODE_COAL, resp.data[0].id, resp.data[0].coal)
            // 处理面包屑
            this.crumbA = this.global.coal_name
          }
        }).then(() => {
          this.getRequest(
              "/coalManage/getSystemInfoByCoalId/?coalId=" + this.global.coal_id
          ).then((resp) => {
            if (resp.data) {
              // 处理全局变量
              this.$changeGlobalVar(this.global.MODE_SYSTEM, resp.data[0].id, resp.data[0].name)
              // 处理面包屑
              this.crumbB = this.global.system_name

              this.getRequest(
                  "/coalManage/getSubSystemInfoBySystemId/?systemId=" + this.global.system_id
              ).then((resp) => {
                if (resp.data) {
                  // 处理全局变量
                  this.$changeGlobalVar(this.global.MODE_SUB_SYSTEM, resp.data[0].id, resp.data[0].name)
                  // 处理面包屑
                  this.crumbC = this.global.subSystem_name
                  this.getRequest(
                      "/coalManage/getEquipmentInfoBySubSystemId/?subSystemId=" + this.global.subSystem_id
                  ).then((resp) => {
                    if (resp.data) {
                      // 处理全局变量
                      this.$changeGlobalVar(this.global.MODE_EQUIPMENT, resp.data[0].id, resp.data[0].name)
                      // 处理面包屑
                      this.crumbD = this.global.equipment_name
                      this.initDataWithGlobalVar()
                    }
                  })
                }
              })
            }
          })
        })
      }else {
        if (this.global.system_id === 0) {
          this.getRequest(
              "/coalManage/getSystemInfoByCoalId/?coalId=" + this.global.coal_id
          ).then((resp) => {
            if (resp.data) {
              // 处理全局变量
              this.$changeGlobalVar(this.global.MODE_SYSTEM, resp.data[0].id, resp.data[0].name)
              // 处理面包屑
              this.crumbB = this.global.system_name
              this.getRequest(
                  "/coalManage/getSubSystemInfoBySystemId/?systemId=" + this.global.system_id
              ).then((resp) => {
                if (resp.data) {
                  // 处理全局变量
                  this.$changeGlobalVar(this.global.MODE_SUB_SYSTEM, resp.data[0].id, resp.data[0].name)
                  // 处理面包屑
                  this.crumbC = this.global.subSystem_name
                  this.initDataWithGlobalVar()
                }
              })
            }
          })
        } else {
          if (this.global.subSystem_id === 0) {
            this.getRequest(
                "/coalManage/getSubSystemInfoBySystemId/?systemId=" + this.global.system_id
            ).then((resp) => {
              if (resp.data) {
                console.log("check:", resp.data)
                // 处理全局变量
                this.$changeGlobalVar(this.global.MODE_SUB_SYSTEM, resp.data[0].id, resp.data[0].name)
                // 处理面包屑
                this.crumbC = this.global.subSystem_name
              }
            })
          } else {
            if (this.global.equipment_id === 0) {
              this.getRequest(
                  "/coalManage/getEquipmentInfoBySubSystemId/?subSystemId=" + this.global.subSystem_id
              ).then((resp) => {
                if (resp.data) {
                  console.log("check:", resp.data)
                  // 处理全局变量
                  this.$changeGlobalVar(this.global.MODE_EQUIPMENT, resp.data[0].id, resp.data[0].name)
                  // 处理面包屑
                  this.crumbD = this.global.equipment_name
                }
              }).then(() => {
                this.initDataWithGlobalVar()
              })
            }
          }
        }
      }
    },
    initDataWithGlobalVar() {
      this.dataListLoading = true
      // 处理面包屑
      this.crumbA = this.global.coal_name
      this.crumbB = this.global.system_name
      this.crumbC = this.global.subSystem_name
      this.crumbD = this.global.equipment_name
      this.getRequest(
          "/subEquipmentManage/getSubEquipmentInfoByEquipmentId?equipmentId=" +
          this.global.equipment_id + "&page=" + this.page + "&size=" + this.size
      ).then((resp) => {
        if (resp.data) {
          this.tableData = resp.data.data;
          this.total = resp.data.total;
          this.totalMPNum = resp.data.totalMPNum;
          this.overViewFlag = true;
        }
        this.dataListLoading = false
      });
    },
    handleSearch() {
      this.resetSizePage()
      if (this.$refs["cascade"].checkedValue === null ||this.$refs["cascade"].checkedValue.length == 0) {
        this.initDataWithGlobalVar()
      } else {
        //处理面包屑
        this.crumbA = this.$refs["cascade"].getCheckedNodes()[0].pathNodes[0].label
        this.crumbB = this.$refs["cascade"].getCheckedNodes()[0].pathNodes[1].label
        this.crumbC = this.$refs["cascade"].getCheckedNodes()[0].pathNodes[2].label
        this.crumbD = this.$refs["cascade"].getCheckedNodes()[0].pathNodes[3].label

        // 处理全局变量
        this.$changeGlobalVar(this.global.MODE_COAL, this.$refs["cascade"].getCheckedNodes()[0].pathNodes[0].value, this.crumbA)
        this.$changeGlobalVar(this.global.MODE_SYSTEM, this.$refs["cascade"].getCheckedNodes()[0].pathNodes[1].value, this.crumbB)
        this.$changeGlobalVar(this.global.MODE_SUB_SYSTEM, this.$refs["cascade"].getCheckedNodes()[0].pathNodes[2].value, this.crumbC)
        this.$changeGlobalVar(this.global.MODE_EQUIPMENT, this.$refs["cascade"].getCheckedNodes()[0].pathNodes[3].value, this.crumbD)

		let arr=[...new Set([this.crumbA,this.crumbB,this.crumbC,this.crumbD])];
		this.selectedOptions=arr.toString().replaceAll(","," | ");  
		this.$forceUpdate();
        this.initDataWithGlobalVar()
      }
    },
    initData(){
      this.dataListLoading = true
      this.getRequest(
          "/subEquipmentManage/getSubEquipmentInfoByPage/?page=" +
          this.page +
          "&size=" +
          this.size + "&userId=" + this.currOperator
      ).then((resp) => {
        if (resp.data) {
          this.tableData = resp.data.data;
          this.total = resp.data.total;
          this.totalMPNum = resp.data.totalMPNum;
          console.log("this.totalMPNum:"+this.totalMPNum);
          this.overViewFlag = true;
        }
        this.dataListLoading = false
      });
    },
    handleJump() {
      this.dataListLoading = true;
      this.getRequest(
          "/subEquipmentManage/getSubEquipmentInfoByEquipmentId?equipmentId=" +
          this.$route.query.dataQualityDeviceId + "&page=" + this.page + "&size=" + this.size
      ).then((resp) => {
        if (resp.data) {
          this.tableData = resp.data.data;
          this.total = resp.data.total;
          this.overViewFlag = true;
        }
        this.dataListLoading = false
      });
    },
    async getAllCoal(callback) {
      this.getRequest('/dataList/getAllCoal/').then(resp=>{
        if (resp.data){
          callback(resp.data);
          console.log(resp.data)
        }
      });
    },
    async getSystem(coalId, callback) {
      this.getRequest('/coalManage/getSystemInfoByCoalId?coalId=' + coalId).then((resp) => {
        if (resp.data) {
          console.log(resp.data)
          callback(resp.data);
        }
      })
    },
    async getSubSystem(systemId, callback) {
      this.getRequest('/coalManage/getSubSystemInfoBySystemId?systemId=' + systemId).then((resp) => {
        if (resp.data) {
          console.log(resp.data)
          callback(resp.data);
        }
      })
    },
    async getEquipment(subSystemId, callback) {
      this.getRequest('/coalManage/getEquipmentInfoBySubSystemId?subSystemId=' + subSystemId).then((resp) => {
        if (resp.data) {
          console.log(resp.data)
          callback(resp.data);
        }
      })
    },
    back(count) {
      history.go(count)
    }
  }
}
</script>

<style> 
  #startPointId {
    left: -500px;
    opacity: 0;
  }
</style>
